export const getImageDimensions = (
  file: File
): Promise<{ width: number; height: number }> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = (event) => {
      const img = new Image();
      img.onload = () => {
        resolve({ width: img.width, height: img.height });
      };
      img.onerror = (error) => {
        reject(error);
      };
      img.src = event.target?.result as string;
    };

    reader.onerror = (error) => {
      reject(error);
    };

    reader.readAsDataURL(file);
  });
};

export const base64ToFile = (base64String: string, fileName: string): File => {
  const arr = base64String.split(',');
  const mime = arr[0].match(/:(.*?);/)![1];
  const bstr = atob(arr[1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }

  return new File([u8arr], fileName, { type: mime });
};

export const getContrastingColor = (hex: string): string => {
  // Remove the hash if present
  hex = hex.replace(/^#/, '');

  // Parse the hex color into its red, green, and blue components
  const r = parseInt(hex.substring(0, 2), 16);
  const g = parseInt(hex.substring(2, 4), 16);
  const b = parseInt(hex.substring(4, 6), 16);

  // Calculate the relative luminance of the color
  const luminance =
    0.2126 * (r / 255) + 0.7152 * (g / 255) + 0.0722 * (b / 255);

  // Return black for light colors, white for dark colors
  return luminance > 0.5 ? '#1c2024' : '#fcfcfd';
};

export const getFileSizeInMB = async (url: string) => {
  try {
    const response = await fetch(url, {
      method: 'GET',
      headers: new Headers({
        Range: 'bytes=0-0',
      }),
    });

    const contentRange = response.headers.get('content-range')?.split('/')[1];

    if (contentRange) {
      const sizeInMB = (parseInt(contentRange, 10) / (1024 * 1024)).toFixed(2); // Convert bytes to mb
      return sizeInMB;
    } else {
      return 'Size not available';
    }
  } catch (error) {
    console.error('Error fetching file size:', error);
    return 'Error fetching size';
  }
};

export const formatDate = (inputDate: string): string => {
  const months = [
    'jan',
    'feb',
    'mar',
    'apr',
    'may',
    'jun',
    'jul',
    'aug',
    'sep',
    'oct',
    'nov',
    'dec',
  ];

  const date = new Date(inputDate);

  // Extract day, month, and year from the date
  const day = date.getDate();
  const month = months[date.getMonth()];
  const year = date.getFullYear();

  // Format the date as 'day. month year.'
  return `${day}. ${month} ${year}.`;
};

export const formatReportDate = (dateString: string): string => {
  const date = new Date(dateString);
  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const year = String(date.getFullYear()).slice(-2);
  return `${day}.${month}.${year}`;
};

export const formatNumberWithThousands = (number: number): string => {
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
};

export const formatDateToShortString = (dateString: string): string => {
  const date = new Date(dateString);

  // Array of month names in abbreviated form
  const months: string[] = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];

  const day = date.getDate().toString().padStart(2, '0'); // Ensures two-digit day
  const month = months[date.getMonth()]; // Get month name

  return `${day}. ${month}`;
};
