import {
  Dialog,
  Button,
  Flex,
  Text,
  TextField,
  IconButton,
  Link,
} from '@radix-ui/themes';
import { useAppDispatch, useAppSelector } from '@/hooks';
import { useEffect, useRef, useState } from 'react';
import useCaptureEvent from '@/hooks/useCaptureEvent';
import { userApi } from '@/services/user';
import {
  EyeClosedIcon,
  EyeOpenIcon,
  CheckCircledIcon,
  Cross1Icon,
} from '@radix-ui/react-icons';
import { useForm, SubmitHandler } from 'react-hook-form';
import { toast } from 'react-toastify';

interface IChangePassword {
  verificationCode: string;
  newPassword: string;
  newPasswordConfirmation: string;
}

export const ChangePasswordDialog = () => {
  const dispatch = useAppDispatch();
  const captureEvent = useCaptureEvent();
  const { currentUser } = useAppSelector((state) => state.userReducer);

  const [open, setOpen] = useState<boolean>(false);
  const [lowercaseUppercaseError, setLowercaseUppercaseError] =
    useState<boolean>(false);
  const [minCharsError, setMinCharsError] = useState<boolean>(false);
  const [numberError, setNumberError] = useState<boolean>(false);
  const [specialCharError, setSpecialCharError] = useState<boolean>(false);
  const [step, setStep] = useState<number>(1);

  const [isLoadingChangePassword, setIsLoadingChangePassword] =
    useState<boolean>(false);
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [showResetPassword, setShowResetPassword] = useState<boolean>(false);

  const {
    register: registerPassword,
    handleSubmit: handleSubmitPassword,
    formState: { errors: errorsPassword },
    watch,
    resetField,
    clearErrors,
  } = useForm<IChangePassword>();
  const onChangePasswordSubmit: SubmitHandler<IChangePassword> = (data) =>
    ChangePasswordSubmit(data);

  const ChangePasswordSubmit = async (data: IChangePassword) => {
    try {
      let checkLowercaseUppercase = false;
      let checkminChars = false;
      let checknumber = false;
      let checkspecialChar = false;
      if (!checkPassword('lowercaseUppercase')) {
        setLowercaseUppercaseError(true);
        checkLowercaseUppercase = true;
      }
      if (!checkPassword('minChars')) {
        setMinCharsError(true);
        checkminChars = true;
      }
      if (!checkPassword('number')) {
        setNumberError(true);
        checknumber = true;
      }
      if (!checkPassword('specialChar')) {
        setSpecialCharError(true);
        checkspecialChar = true;
      }

      if (
        checkLowercaseUppercase ||
        checkminChars ||
        checknumber ||
        checkspecialChar
      ) {
        return;
      }

      setIsLoadingChangePassword(true);
      const inputs = {
        verification_code: data.verificationCode,
        new_password: data.newPassword,
        new_password_confirmation: data.newPasswordConfirmation,
      };
      await dispatch(
        userApi.endpoints.changePassword.initiate(inputs)
      ).unwrap();
      toast.success('Password successfully changed', {
        toastId: 'change_password_updated',
        position: 'bottom-right',
        autoClose: 7000,
      });
      captureEvent('Password changed', { email: currentUser?.email });
      resetField('verificationCode');
      resetField('newPassword');
      resetField('newPasswordConfirmation');
      setOpen(false);
    } catch (error: any) {
      console.log(error.data);

      toast.error(error?.data?.message || 'An error occurred.', {
        toastId: 'change_password_error',
        position: 'bottom-right',
        autoClose: 7000,
      });

      parseAndRenderErrors(error.data);
    } finally {
      setIsLoadingChangePassword(false);
    }
  };

  const parseAndRenderErrors = (response: any) => {
    const errors = response.errors;

    Object.keys(errors).forEach((field, index) => {
      const errorMessages = errors[field].join('. ');
      toast.error(errorMessages, {
        toastId: `error_${field}_${index}`,
        position: 'bottom-right',
        autoClose: 7000,
      });
    });
  };

  const password = useRef({});
  password.current = watch('newPassword', '');
  const newPasswordConfirmation = useRef({});
  newPasswordConfirmation.current = watch('newPasswordConfirmation', '');

  useEffect(() => {
    if (password.current === newPasswordConfirmation.current) {
      clearErrors('newPasswordConfirmation');
    }
  }, [password.current, newPasswordConfirmation.current]);

  useEffect(() => {
    if (password.current) {
      setLowercaseUppercaseError(false);
      setMinCharsError(false);
      setNumberError(false);
      setSpecialCharError(false);
    }
  }, [password.current]);

  const checkPassword = (
    rule: 'minChars' | 'lowercaseUppercase' | 'number' | 'specialChar'
  ) => {
    switch (rule) {
      case 'minChars':
        return /.{8,}/.test(password.current as string);
      case 'lowercaseUppercase':
        return /(?=.*[a-z])(?=.*[A-Z])/.test(password.current as string);
      case 'number':
        return /(?=.*\d)/.test(password.current as string);
      case 'specialChar':
        return /(?=.*[!@#$%^&*(),.?":{}|<>])/.test(password.current as string);
      default:
        return false;
    }
  };

  const [isSendingCode, setIsSendingCode] = useState<boolean>(false);

  const sendVerificationCode = async () => {
    try {
      setIsSendingCode(true);
      await dispatch(
        userApi.endpoints.sendVerificationCode.initiate()
      ).unwrap();
      toast.success('Verification code sent to your email', {
        toastId: 'send_code_updated',
        position: 'bottom-right',
        autoClose: 7000,
      });
      captureEvent('Verification code sent', { email: currentUser?.email });
      setStep(2);
    } catch (error: any) {
      console.log(error.data);

      toast.error(error.data.message, {
        toastId: 'send_code_error',
        position: 'bottom-right',
        autoClose: 7000,
      });
    } finally {
      setIsSendingCode(false);
    }
  };

  return (
    <Dialog.Root open={open} onOpenChange={setOpen}>
      <Dialog.Trigger>
        <Button size="1" variant="ghost" type="button">
          <Text>CHANGE PASSWORD</Text>
        </Button>
      </Dialog.Trigger>

      <Dialog.Content
        aria-describedby={undefined}
        onPointerDownOutside={() =>
          captureEvent('Change password modal closed')
        }
        maxWidth={step === 1 ? '600px' : '400px'}
        minWidth={step === 1 ? '600px' : '400px'}
        style={{ minHeight: '150px' }}
      >
        <Flex
          direction={'row'}
          align={'start'}
          justify={'between'}
          className="header"
        >
          <Dialog.Title as="h1">Change Password </Dialog.Title>
          <Dialog.Close>
            <Cross1Icon
              cursor={'pointer'}
              onClick={() => captureEvent('Change password modal closed')}
            />
          </Dialog.Close>
        </Flex>
        {step === 1 && (
          <Flex mt={'5'} direction={'column'} gap={'6'}>
            <Text size={'5'} weight={'bold'} align={'center'}>
              Step-1 Verify your E-mail Address
            </Text>
            <Text size={'2'} align={'center'}>
              Request the verification code so we can verify your e-mail
              address.
              <br /> The code will be sent to <b>{currentUser?.email}</b>
            </Text>
            <Button
              onClick={() => sendVerificationCode()}
              loading={isSendingCode}
              style={{ alignSelf: 'center' }}
            >
              Request Verification Code
            </Button>
          </Flex>
        )}
        {step === 2 && (
          <form
            onSubmit={handleSubmitPassword(onChangePasswordSubmit)}
            style={{
              display: 'flex',
              flexDirection: 'column',
              gap: '8px',
              marginTop: '12px',
            }}
          >
            <Flex direction={'column'} gap={'0'}>
              <label>
                <Flex justify={'between'} align={'center'}>
                  <Text size={'2'}>Verification Code</Text>
                </Flex>
                <TextField.Root
                  {...registerPassword('verificationCode', {
                    required: {
                      value: true,
                      message: 'Verification Code field is required',
                    },
                  })}
                >
                  {/* <TextField.Slot style={{width:"7px,padding:"0""}}></TextField.Slot>
                <TextField.Slot>
                  <Button
                    size="1"
                    variant="ghost"
                    type="button"
                    onClick={() => sendVerificationCode()}
                  >
                    SEND
                  </Button>
                </TextField.Slot>*/}
                </TextField.Root>
              </label>
              <Flex gap={'1'} align={'center'}>
                <Text size={'1'} color="gray">
                  Didn't receive your code?
                </Text>
                <Link
                  href="javascript:void(0)"
                  onClick={() => sendVerificationCode()}
                  size={'1'}
                  underline={'hover'}
                >
                  Request the code again
                </Link>
              </Flex>
              {errorsPassword.verificationCode && (
                <Text role="alert" size={'1'} color="red">
                  {errorsPassword.verificationCode.message}
                </Text>
              )}
            </Flex>
            <Flex direction={'column'} gap={'0'}>
              <label>
                <Text size={'1'}>New Password</Text>
                <TextField.Root
                  type={showPassword ? 'text' : 'password'}
                  {...registerPassword('newPassword', {
                    required: {
                      value: true,
                      message: 'New Password field is required',
                    },
                  })}
                >
                  <TextField.Slot
                    style={{ width: '7px', padding: '0' }}
                  ></TextField.Slot>
                  <TextField.Slot>
                    <IconButton
                      size="1"
                      variant="ghost"
                      type="button"
                      onClick={() => setShowPassword(!showPassword)}
                    >
                      {showPassword ? (
                        <EyeOpenIcon height="14" width="14" />
                      ) : (
                        <EyeClosedIcon height="14" width="14" />
                      )}
                    </IconButton>
                  </TextField.Slot>
                </TextField.Root>
              </label>
              {errorsPassword.newPassword && (
                <Text role="alert" size={'1'} color="red">
                  {errorsPassword.newPassword.message}
                </Text>
              )}
            </Flex>
            <Flex direction={'column'} gap={'0'}>
              <label>
                <Text size={'2'}>Re-enter New Password</Text>
                <TextField.Root
                  type={showResetPassword ? 'text' : 'password'}
                  {...registerPassword('newPasswordConfirmation', {
                    required: {
                      value: true,
                      message: 'Re-enterd New Password field is required',
                    },
                    validate: (value) =>
                      value === password.current ||
                      'The passwords do not match',
                  })}
                >
                  <TextField.Slot
                    style={{ width: '7px', padding: '0' }}
                  ></TextField.Slot>
                  <TextField.Slot>
                    <IconButton
                      size="1"
                      variant="ghost"
                      type="button"
                      onClick={() => setShowResetPassword(!showResetPassword)}
                    >
                      {showResetPassword ? (
                        <EyeOpenIcon height="14" width="14" />
                      ) : (
                        <EyeClosedIcon height="14" width="14" />
                      )}
                    </IconButton>
                  </TextField.Slot>
                </TextField.Root>
              </label>
              {errorsPassword.newPasswordConfirmation && (
                <Text role="alert" size={'1'} color="red">
                  {errorsPassword.newPasswordConfirmation.message}
                </Text>
              )}
            </Flex>
            <Flex direction={'column'} gap={'4px'} mt={'8px'}>
              <Flex gap={'4px'} align={'center'}>
                <CheckCircledIcon
                  color={
                    lowercaseUppercaseError
                      ? 'red'
                      : checkPassword('lowercaseUppercase')
                      ? 'green'
                      : 'gray'
                  }
                />
                <Text
                  role="alert"
                  size={'1'}
                  color={
                    lowercaseUppercaseError
                      ? 'red'
                      : checkPassword('lowercaseUppercase')
                      ? 'green'
                      : 'gray'
                  }
                >
                  Password must contain 1 upper case and 1 lowecase letter
                </Text>
              </Flex>
              <Flex gap={'4px'} align={'center'}>
                <CheckCircledIcon
                  color={
                    numberError
                      ? 'red'
                      : checkPassword('number')
                      ? 'green'
                      : 'gray'
                  }
                />
                <Text
                  role="alert"
                  size={'1'}
                  color={
                    numberError
                      ? 'red'
                      : checkPassword('number')
                      ? 'green'
                      : 'gray'
                  }
                >
                  Password must contain 1 number
                </Text>
              </Flex>
              <Flex gap={'4px'} align={'center'}>
                <CheckCircledIcon
                  color={
                    specialCharError
                      ? 'red'
                      : checkPassword('specialChar')
                      ? 'green'
                      : 'gray'
                  }
                />
                <Text
                  role="alert"
                  size={'1'}
                  color={
                    specialCharError
                      ? 'red'
                      : checkPassword('specialChar')
                      ? 'green'
                      : 'gray'
                  }
                >
                  Password must contain 1 special character
                </Text>
              </Flex>
              <Flex gap={'4px'} align={'center'}>
                <CheckCircledIcon
                  color={
                    minCharsError
                      ? 'red'
                      : checkPassword('minChars')
                      ? 'green'
                      : 'gray'
                  }
                />
                <Text
                  role="alert"
                  size={'1'}
                  color={
                    minCharsError
                      ? 'red'
                      : checkPassword('minChars')
                      ? 'green'
                      : 'gray'
                  }
                >
                  Password must be over 8 characters
                </Text>
              </Flex>
            </Flex>
            <Flex gap="3" justify="end" mt={'4'} className="footer">
              <Dialog.Close>
                <Button
                  variant="soft"
                  color="gray"
                  onClick={() => captureEvent('Change password modal closed')}
                >
                  Cancel
                </Button>
              </Dialog.Close>
              <Button type="submit" loading={isLoadingChangePassword}>
                Change password
              </Button>
            </Flex>
          </form>
        )}
      </Dialog.Content>
    </Dialog.Root>
  );
};
