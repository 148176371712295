import { useState } from 'react';
import { useAppDispatch, useAppSelector } from './reduxHooks';
import {
  setIsCreatingVariations,
  setActiveVariation,
} from '@/redux/slices/collection';
import { designsApi } from '@/services/designs';
import { psdEngineApi } from '@/services/psdEngine';
import { base64ToFile, getImageDimensions } from '@/helpers';

function useAddAiBackgroundToSmartObject() {
  const dispatch = useAppDispatch();
  const { activeSmartObject, mockup } = useAppSelector(
    (state) => state.collectionReducer
  );
  const { currentUserPrompt } = useAppSelector(
    (state) => state.aiBackgroundsReducer
  );
  const [isApplying, setIsApplying] = useState(false);

  const [uploadDesigns] = designsApi.useUploadDesignsMutation();
  const { myProjects } = useAppSelector((state) => state.workspaceReducer);
  const [addAsset] = psdEngineApi.useAddDesignAssetMutation();
  const [createMockupVariations] =
    psdEngineApi.useCreateMockupVariationsMutation();

  const applyOnSmartObject = async (
    base64Image: string,
    collectionId: string
  ) => {
    setIsApplying(true);
    const formData = new FormData();
    const file = base64ToFile(base64Image, `${currentUserPrompt}.png`);
    formData.append('name', file.name);
    formData.append('type', file.type);
    formData.append('images[0][file]', file);
    formData.append('images[0][is_ai_generated]', '1');

    try {
      const uploadedDesign = await uploadDesigns(formData);
      const fileDimensions = await getImageDimensions(file);
      addDesignsToMockup(
        (uploadedDesign as any).data.data[0],
        file,
        fileDimensions,
        myProjects,
        collectionId
      );
    } catch (error) {
      console.error('Error uploading design:', error);
      setIsApplying(false);
    }
  };

  const addDesignsToMockup = async (
    designId: number,
    file: File,
    fileDimensions: {
      width: number;
      height: number;
    },
    myProjects: any[],
    collectionId: string
  ) => {
    dispatch(setIsCreatingVariations(true));
    const createdMockupVariations: any[] = [];

    try {
      const formData = new FormData();
      formData.append('smart_object_id', activeSmartObject.id.toString());
      formData.append('assets[0][design_id]', designId.toString());
      formData.append(
        'assets[0][top]',
        Math.floor(
          (activeSmartObject.height - fileDimensions.height) / 2
        ).toString()
      );
      formData.append(
        'assets[0][left]',
        Math.floor(
          (activeSmartObject.width - fileDimensions.width) / 2
        ).toString()
      );
      formData.append('assets[0][width]', fileDimensions.width.toString());
      formData.append('assets[0][height]', fileDimensions.height.toString());

      const addedAsset = await addAsset({ payload: formData }).unwrap();
      setIsApplying(false);

      const response = await createMockupVariations({
        payload: {
          mockup_id: mockup?.id,
          smart_object_id: activeSmartObject.id,
          asset_id: addedAsset.data[0].id,
        },
      }).unwrap();
      createdMockupVariations.push(response.data);
    } catch (error) {
      console.error('Error processing file:', error);
      setIsApplying(false);
      dispatch(setIsCreatingVariations(false));
      return;
    }

    dispatch(setIsCreatingVariations(false));

    const firstOfCreatedVariations = createdMockupVariations[0].find(
      (variation: any) => variation.isFetching
    );

    await new Promise((resolve) => setTimeout(resolve, 250));

    dispatch(
      setActiveVariation(
        firstOfCreatedVariations || createdMockupVariations[0][0]
      )
    );
  };

  return {
    applyOnSmartObject,
    isApplying,
  };
}

export default useAddAiBackgroundToSmartObject;
