import { authApi } from '@/services/auth';
import { createSlice } from '@reduxjs/toolkit';
import posthog from 'posthog-js';

interface IAuthState {
  accessToken: string | null;
  refreshToken: string | null;
  isAuthenticated: boolean;
  forgotPasswordSuccessEmail: string;
}

const accessToken = localStorage.getItem('accessToken') as string;
const refreshToken = localStorage.getItem('refreshToken') as string;

const initialState: IAuthState = {
  accessToken: accessToken || null,
  refreshToken: refreshToken || null,
  isAuthenticated: !!accessToken,
  forgotPasswordSuccessEmail: '',
};

export const authSlice = createSlice({
  initialState,
  name: 'authSlice',
  reducers: {
    logout: (state) => {
      state = initialState;
      localStorage.removeItem('accessToken');
      localStorage.removeItem('refreshToken');
    },
    tokenRefresh: (state, { payload }) => {
      state.accessToken = payload.accessToken;
      //state.refreshToken = payload.refreshToken;
      localStorage.setItem('accessToken', payload.accessToken || null);
      //localStorage.setItem('refreshToken', payload.refreshToken || null);
    },
    setForgotPasswordSuccessEmail: (state, { payload }) => {
      state.forgotPasswordSuccessEmail = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      authApi.endpoints.googleAuthCallback.matchFulfilled,
      (state, response) => {
        // console.log(response);
        if (!response.payload.data.token.accessToken) return;
        state.accessToken = response.payload.data.token.accessToken;
        // state.refreshToken = response.payload.refreshToken;
        localStorage.setItem(
          'accessToken',
          response.payload.data.token.accessToken || null
        );
        // localStorage.setItem(
        //   'refreshToken',
        //   response.payload.data.refresh_token || null
        // );

        if (response.payload.data.isAlreadyRegistered) {
          posthog.capture('User Logged In', null, { send_instantly: true });
        } else {
          (window as any).dataLayer = (window as any).dataLayer || [];
          (window as any).dataLayer.push({
            event: 'registrationComplete',
          });
          window.fpr('referral', { email: response.payload.data.email });
          posthog.capture(
            'User Registered',
            {
              $set: {
                auth_google: true,
                email: response.payload.data.email || undefined,
              },
            },
            { send_instantly: true }
          );
        }

        state.isAuthenticated = true;
      }
    ),
      builder.addMatcher(
        authApi.endpoints.login.matchFulfilled,
        (state, response) => {
          if (!response.payload.data.token) return;
          state.accessToken = response.payload.data.token;
          localStorage.setItem(
            'accessToken',
            response.payload.data.token || null
          );

          posthog.capture('User Logged In', null, { send_instantly: true });

          state.isAuthenticated = true;
        }
      ),
      builder.addMatcher(
        authApi.endpoints.register.matchFulfilled,
        (state, response) => {
          if (!response.payload.data.token) return;
          state.accessToken = response.payload.data.token;
          localStorage.setItem(
            'accessToken',
            response.payload.data.token || null
          );

          (window as any).dataLayer = (window as any).dataLayer || [];
          (window as any).dataLayer.push({
            event: 'registrationComplete',
          });

          posthog.capture(
            'User Registered',
            {
              $set: {
                auth_regular: true,
                email: response.meta.arg.originalArgs.email,
              },
            },
            { send_instantly: true }
          );

          state.isAuthenticated = true;
        }
      );
  },
});

export default authSlice.reducer;

export const { logout, tokenRefresh, setForgotPasswordSuccessEmail } =
  authSlice.actions;
