import { Button, Flex, AlertDialog } from '@radix-ui/themes';
import { useAppDispatch } from '@/hooks';
import { psdManagementApi } from '@/services/psdManagement';
import { PrivatePhotoshopFile } from '@/services/types';
import posthog from 'posthog-js';
import { useState, forwardRef, useImperativeHandle } from 'react';
import useCaptureEvent from '@/hooks/useCaptureEvent';
import { toast } from 'react-toastify';

interface IReprocessPhotoshopFile {
  privatePsd: PrivatePhotoshopFile;
}

export const ReprocessPhotoshopFile = forwardRef(
  ({ privatePsd }: IReprocessPhotoshopFile, ref) => {
    const dispatch = useAppDispatch();
    const captureEvent = useCaptureEvent();
    const [open, setOpen] = useState<boolean>(false);
    const [isReprocessing, setIsReprocessing] = useState<boolean>(false);

    useImperativeHandle(ref, () => ({
      openDialog() {
        setOpen(true);
      },
      closeDialog() {
        setOpen(false);
      },
    }));

    const reprocessPhotoshopFileAction = async (id: number) => {
      setIsReprocessing(true);
      await fetch(`${API_BASE_URL}psd/process`, {
        method: 'POST',
        headers: new Headers({
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
          'Content-Type': 'application/json',
        }),
        body: JSON.stringify({ psd_id: id }),
      })
        .then((res) => res.json())
        .then((data) => {
          dispatch(
            psdManagementApi.endpoints.getPrivatePsds.initiate(null!, {
              forceRefetch: true,
            })
          );
          toast.success('PSD file successfully reprocessed.', {
            toastId: 'reprocess_mockup_success',
            position: 'bottom-right',
            autoClose: 5000,
          });
          captureEvent('PSD Mockup reprocessed');
        })
        .catch((error) => {
          captureEvent('PSD Mockup processing failed', {
            error: error,
          });
          toast.error(error.data.message, {
            toastId: 'reprocess_mockup_error',
            position: 'bottom-right',
            autoClose: 7000,
          });
        })
        .finally(() => {
          setIsReprocessing(false);
          setOpen(false);
        });
    };

    return (
      <AlertDialog.Root open={open} onOpenChange={setOpen}>
        <AlertDialog.Content style={{ maxWidth: 450 }}>
          <AlertDialog.Title>Reprocess Photoshop file</AlertDialog.Title>
          <AlertDialog.Description size="2">
            Are you sure you want to reprocess this Photoshop file?
          </AlertDialog.Description>

          <Flex gap="3" mt="4" justify="end">
            <AlertDialog.Cancel>
              <Button variant="soft" color="gray">
                Close
              </Button>
            </AlertDialog.Cancel>
            <Button
              loading={isReprocessing}
              variant="solid"
              color="green"
              onClick={() => reprocessPhotoshopFileAction(privatePsd.psd_id)}
            >
              Reprocess
            </Button>
          </Flex>
        </AlertDialog.Content>
      </AlertDialog.Root>
    );
  }
);
