import { ColorSet, colorsApi } from '@/services/colors';
import { DailyRenderReport, reportsApi } from '@/services/reports';
import { createSlice } from '@reduxjs/toolkit';

interface IReportsState {
  dailyRendersLast30Days: DailyRenderReport[];
}

const initialState: IReportsState = {
  dailyRendersLast30Days: null!,
};

export const reportsSlice = createSlice({
  initialState,
  name: 'reportsSlice',
  reducers: {
    resetReportsState: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      reportsApi.endpoints.getDailyRendersLast30Days.matchFulfilled,
      (state, response) => {
        state.dailyRendersLast30Days = response.payload.data;
      }
    );
  },
});

export default reportsSlice.reducer;

export const {} = reportsSlice.actions;
