import { useAppDispatch, useAppSelector } from '@/hooks';
import { Flex, TextField, Text, Slider } from '@radix-ui/themes';
import { useState, useEffect, useCallback, useRef } from 'react';
import { SmartObject } from '@/services/types';
import { psdEngineApi } from '@/services/psdEngine';
import { setAllVariationIsFetching } from '@/redux/slices/collection';
import useCaptureEvent from '@/hooks/useCaptureEvent';
import './adjustment-layer-controls.scss';

interface IAdjustmentLayerControls {
  smartObject: SmartObject;
}

type effectType =
  | 'opacity'
  | 'brightness'
  | 'contrast'
  | 'vibrance'
  | 'saturation';

const useDebouncedCallback = (func: any, wait: any) => {
  const timeout = useRef();

  return useCallback(
    (...args: any) => {
      const later = () => {
        clearTimeout(timeout.current);
        func(...args);
      };

      clearTimeout(timeout.current);
      (timeout as any).current = setTimeout(later, wait);
    },
    [func, wait]
  );
};

export const AdjustmentLayerControls = ({
  smartObject,
}: IAdjustmentLayerControls) => {
  const dispatch = useAppDispatch();
  const { mockup } = useAppSelector((state) => state.collectionReducer);
  const [contrast, setContrast] = useState<number | string>(0);
  const [brightness, setBrightness] = useState<number | string>(0);
  const [opacity, setOpacity] = useState<number>(100);
  const [vibrance, setVibrance] = useState<number | string>(0);
  const [saturation, setSaturation] = useState<number | string>(0);
  const captureEvent = useCaptureEvent();

  const [updateSmartObjectAdjustmentLayers] =
    psdEngineApi.useUpdateSmartObjectAdjustmentLayersMutation();

  useEffect(() => {
    setContrast(smartObject.adjustment_layers.contrast);
    setBrightness(smartObject.adjustment_layers.brightness);
    setOpacity(smartObject.adjustment_layers.opacity);
    setVibrance(smartObject.adjustment_layers.vibrance);
    setSaturation(smartObject.adjustment_layers.saturation);
  }, [smartObject]);

  const onAdjustmentChange = useDebouncedCallback(
    async (payload: {
      type: effectType;
      data: {
        contrast: number;
        brightness: number;
        opacity: number;
        vibrance: number;
        saturation: number;
      };
    }) => {
      const data = {
        brightness: payload.data.brightness,
        contrast: payload.data.contrast,
        opacity: payload.data.opacity,
        vibrance: payload.data.vibrance,
        saturation: payload.data.saturation,
      };

      switch (payload.type) {
        case 'brightness':
          captureEvent(`Effect: brightness`, {
            value: payload.data.brightness,
          });
          break;
        case 'contrast':
          captureEvent(`Effect: contrast`, {
            value: payload.data.contrast,
          });
          break;
        case 'opacity':
          captureEvent(`Effect: opacity`, {
            value: payload.data.opacity,
          });
          break;
        case 'vibrance':
          captureEvent(`Effect: vibrance`, {
            value: payload.data.vibrance,
          });
          break;
        case 'saturation':
          captureEvent(`Effect: saturation`, {
            value: payload.data.saturation,
          });
          break;
        default:
          captureEvent(`Effect: opacity`, {
            value: payload.data.opacity,
          });
      }

      await updateSmartObjectAdjustmentLayers({
        soId: smartObject.id,
        data: data,
      });

      dispatch(setAllVariationIsFetching(true));

      await dispatch(
        psdEngineApi.endpoints.getMockupSmartObjects.initiate(
          {
            mockup_id: mockup.id,
          },
          { forceRefetch: true }
        )
      ).unwrap();
    },
    150
  );

  const handleContrastChange = (value: number | string) => {
    if (value === '-' || value === '') {
      return;
    }
    const parsedValue = parseInt(value.toString());
    if (!isNaN(parsedValue)) {
      setContrast(parsedValue);
      onAdjustmentChange({
        type: 'contrast',
        data: {
          contrast: parsedValue,
          brightness: brightness,
          opacity: opacity,
          vibrance: vibrance,
          saturation: saturation,
        },
      });
    }
  };

  const handleBrightnessChange = (value: number | string) => {
    if (value === '-' || value === '') {
      return;
    }
    const parsedValue = parseInt(value.toString());
    if (!isNaN(parsedValue)) {
      setBrightness(parsedValue);
      onAdjustmentChange({
        type: 'brightness',
        data: {
          contrast: contrast,
          brightness: parsedValue,
          opacity: opacity,
          vibrance: vibrance,
          saturation: saturation,
        },
      });
    }
  };

  const handleOpacityChange = (value: number) => {
    setOpacity(value);
    onAdjustmentChange({
      type: 'opacity',
      data: {
        contrast: contrast,
        brightness: brightness,
        opacity: value,
        vibrance: vibrance,
        saturation: saturation,
      },
    });
  };

  const handleVibranceChange = (value: number | string) => {
    if (value === '-' || value === '') {
      return;
    }
    const parsedValue = parseInt(value.toString());
    if (!isNaN(parsedValue)) {
      setVibrance(parsedValue);
      onAdjustmentChange({
        type: 'vibrance',
        data: {
          contrast: contrast,
          brightness: brightness,
          opacity: opacity,
          vibrance: parsedValue,
          saturation: saturation,
        },
      });
    }
  };

  const handleSaturationChange = (value: number | string) => {
    if (value === '-' || value === '') {
      return;
    }
    const parsedValue = parseInt(value.toString());
    if (!isNaN(parsedValue)) {
      setSaturation(parsedValue);
      onAdjustmentChange({
        type: 'saturation',
        data: {
          contrast: contrast,
          brightness: brightness,
          opacity: opacity,
          vibrance: vibrance,
          saturation: parsedValue,
        },
      });
    }
  };

  return (
    <Flex
      justify={'between'}
      direction={'column'}
      width={'100%'}
      gap={'3'}
      px={'4'}
      className="effects-wrapper"
    >
      {/* <Text weight={'medium'} size={'2'}>
        Adjustments
      </Text> */}

      <Flex direction={'column'}>
        <Text size={'1'}>Opacity</Text>
        <Flex align={'center'} gap={'3'}>
          <Slider
            value={[opacity]}
            onValueChange={(value) => handleOpacityChange(value[0])}
            size={'1'}
            min={0}
            max={100}
          />
          <TextField.Root
            placeholder="0"
            type="number"
            size={'1'}
            min={0}
            max={100}
            value={opacity}
            onChange={(e) => handleOpacityChange(parseInt(e.target.value))}
            style={{ textAlign: 'center', width: '35px', height: '25px' }}
          />
        </Flex>
      </Flex>
      <Flex direction={'column'}>
        <Text size={'1'}>Brightness</Text>
        <Flex align={'center'} gap={'3'}>
          <Slider
            value={[brightness as number]}
            onValueChange={(value) => handleBrightnessChange(value[0])}
            size={'1'}
            min={-150}
            max={150}
          />
          <TextField.Root
            placeholder="0"
            type="number"
            size={'1'}
            min={-150}
            max={150}
            value={brightness}
            onChange={(e) => {
              const value = e.target.value;
              setBrightness(value);

              if (value === '-' || /^-?\d*$/.test(value)) {
                handleBrightnessChange(value);
              }
            }}
            style={{ textAlign: 'center', width: '35px', height: '25px' }}
          />
        </Flex>
      </Flex>
      <Flex direction={'column'}>
        <Text size={'1'}>Contrast</Text>
        <Flex align={'center'} gap={'3'}>
          <Slider
            value={[contrast as number]}
            onValueChange={(value) => handleContrastChange(value[0])}
            size={'1'}
            min={-100}
            max={100}
          />
          <TextField.Root
            placeholder="0"
            type="number"
            size={'1'}
            min={-100}
            max={100}
            value={contrast}
            onChange={(e) => {
              const value = e.target.value;
              setContrast(value);

              if (value === '-' || /^-?\d*$/.test(value)) {
                handleContrastChange(value);
              }
            }}
            style={{ textAlign: 'center', width: '35px', height: '25px' }}
          />
        </Flex>
      </Flex>
      <Flex direction={'column'}>
        <Text size={'1'}>Vibrance</Text>
        <Flex align={'center'} gap={'3'}>
          <Slider
            value={[vibrance as number]}
            onValueChange={(value) => handleVibranceChange(value[0])}
            size={'1'}
            min={-100}
            max={100}
          />
          <TextField.Root
            placeholder="0"
            type="number"
            size={'1'}
            min={-100}
            max={100}
            value={vibrance}
            onChange={(e) => {
              const value = e.target.value;
              setVibrance(value);

              if (value === '-' || /^-?\d*$/.test(value)) {
                handleVibranceChange(value);
              }
            }}
            style={{ textAlign: 'center', width: '35px', height: '25px' }}
          />
        </Flex>
      </Flex>
      <Flex direction={'column'}>
        <Text size={'1'}>Saturation</Text>
        <Flex align={'center'} gap={'3'}>
          <Slider
            value={[saturation as number]}
            onValueChange={(value) => handleSaturationChange(value[0])}
            size={'1'}
            min={-100}
            max={100}
          />
          <TextField.Root
            placeholder="0"
            type="number"
            size={'1'}
            min={-100}
            max={100}
            value={saturation}
            onChange={(e) => {
              const value = e.target.value;
              setSaturation(value);

              if (value === '-' || /^-?\d*$/.test(value)) {
                handleSaturationChange(value);
              }
            }}
            style={{ textAlign: 'center', width: '35px', height: '25px' }}
          />
        </Flex>
      </Flex>
    </Flex>
  );
};
